import request from '@/utils/request'

export function chatFuel(data, params=null) {
  return request({
    url: 'maria/chatfuel',
    method: 'post',
    data,
    params
  })
}

export function submitMariaApplication(data) {
  return request({
    url: 'maria',
    method: 'post',
    data
  })
}

export function submitMariaAttachment(data) {
  return request({
    url: 'maria/upload/file',
    method: 'post',
    data
  })
}